import { React, useState, useEffect, useRef, useCallback } from "react";
import "./CSS/Dashboards.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  selectUser,
  setSuperUserAccounts,
  setExclusiveRights,
  setSelectedDepartment,
  setDataFetched,
} from "./Components/features/userSlice";
import LoadingScreen from "./Components/LoadingScreen";
import AllPagesMasterNavbar from "../src/Components/MasterNavbar/AllPagesMasterNavbar";

const fetchData = async (
  dispatch,
  authToken,
  BASEURL,
  sessiontoken,
  userEmail
) => {
  try {
    const [userAccountsResponse, rightsResponse] = await Promise.all([
      axios.get(`${BASEURL}/getSuperUserAccounts`, {
        headers: { "auth-token": authToken, "session-token": sessiontoken },
      }),
      axios.get(`${BASEURL}/getExcusiveRights`, {
        headers: { "auth-token": authToken, "session-token": sessiontoken },
      }),
    ]);

    const userCode = userAccountsResponse.data.filter(
      (item) => item.EMAILACCOUNT === userEmail
    );

    // EMAILACCOUNT;
    const userCodeToCompare = userCode[0].USERCODE;

    // Filter the rightsResponse based on the extracted USERCODE
    const uniqueExclusiveRights = rightsResponse.data.filter(
      (item) => item.OM_USER_CODE === userCodeToCompare
    );
    // Dispatch actions to store the data in Redux
    dispatch(setSuperUserAccounts(userCodeToCompare));
    dispatch(setExclusiveRights(uniqueExclusiveRights));
    dispatch(setDataFetched());
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

function Dashboard2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userEmail = user?.email;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const selectedDepartment = useSelector(
    (state) => state.user.selectedDepartment
  );
  const [data, setData] = useState([]);
  const [selDepartment, setSelDepartment] = useState(selectedDepartment || "");
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const dataFetched = useSelector((state) => state.user.dataFetched);

  useEffect(() => {
    const fetchDataAndPendingApprovals = async () => {
      if (!dataFetched) {
        await fetchData(dispatch, authToken, BASEURL, sessiontoken, userEmail);
      }

      try {
        const response = await axios.get(`${BASEURL}/getPendingapprovals`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        });

        const departments = response.data.map((item) => item.DEPARTMENT);
        const uniqueDepartments = [...new Set(departments)];
        setData(uniqueDepartments);
      } catch (err) {
        console.log("Error while fetching initial data", err);
      } finally {
        setisloading(false);
      }
    };

    fetchDataAndPendingApprovals();
  }, [dataFetched]);

  useEffect(() => {
    const fetchTableData = async () => {
      if (selDepartment) {
        setisloading(true);
        try {
          const response = await axios.get(
            `${BASEURL}/getPendingapprovals?DEPARTMENT=${selDepartment}`,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          setTableData(response.data);
          setShowTable(true);
        } catch (err) {
          console.log(`Error fetching data for ${selDepartment}:`, err);
        } finally {
          setisloading(false);
        }
      }
    };

    fetchTableData();
  }, [selDepartment]);

  const handleCheckboxClick = (department) => {
    setSelDepartment(department);
    dispatch(setSelectedDepartment(department));
  };

  const handleRowClick = (value, name, right) => {
    // value = VIEWNAME and name = DASHBOARDNAME
    navigate("/DetailedDashboardView", { state: { value, name, right } });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllPagesMasterNavbar />;
      <div className="h2">
        <h1>Welcome To Approval Platform</h1>
      </div>
      <section className="flex justify-center mt-10">
        <div className="flex gap-3">
          <h1>Select Department :</h1>
          {data.map((department, index) => (
            <div key={index} className="flex gap-2">
              <input
                type="checkbox"
                className="w-6"
                id={`department-${index}`}
                checked={selDepartment === department}
                onChange={() => handleCheckboxClick(department)}
              />
              <label className="text-green-600" htmlFor={`department-${index}`}>
                {department}
              </label>
            </div>
          ))}
        </div>
      </section>
      {showTable && (
        <div className="scroll">
          <table
            style={{ textTransform: "uppercase" }}
            className="Payment_data_header"
          >
            <thead className="thead">
              <tr className="Payment_tr">
                <th className="header_payments_vendor">DASHBOARD NAME</th>
                <th className="value_payments_amount">COUNT</th>
              </tr>
            </thead>
            <tbody className="Payments_tbody">
              {tableData
                .filter((value) => {
                  return value.DEPARTMENT === selDepartment;
                })
                .map((value, index) => {
                  return (
                    <tr
                      key={index}
                      className="Payments_tbody_tr"
                      onClick={() =>
                        handleRowClick(
                          value.VIEWNAME,
                          value.DASHBOARDNAME,
                          value.APPROVALRIGHTNAME
                        )
                      }
                    >
                      <td
                        className="td value_payments_vendor"
                        style={{ width: "300px" }}
                      >
                        {value.DASHBOARDNAME}
                      </td>
                      <td
                        className="td value_payments_amount"
                        style={{ width: "60px" }}
                      >
                        {value.TRANSACTION_COUNT}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Dashboard2;
