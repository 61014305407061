import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnBalanceSheet.css";
import moment from "moment";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import Footer from "../../../../Components/Footer";
import LoadingScreen from "../../../../Components/LoadingScreen";

import Loading2 from "../../../../Components/Loading2";
import { useSelector } from "react-redux";

const AnalyticsBasedOnBalanceSheet = () => {
  const [isloading, setIsloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [date, setDate] = useState(new Date().toISOString());
  const [data, setData] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const CompanyCode = useSelector(
    (state) => state?.user?.companyCode?.companyCode
  );
  let TotalAssetAmount = 0;
  let TotalLiabilityAmount = 0;

  const AssetGroupCounts = {};
  const AssetLevelCounts = {};

  useEffect(() => {
    BalanceSheet();
    setIsloading(false);
  }, [date]);

  const assetData = data.filter((item) => item.ASSETLIABILITY === "Asset");
  const liabilityData = data.filter(
    (item) => item.ASSETLIABILITY === "Liability"
  );

  assetData.forEach((item) => {
    if (item.ASSETLIABILITY === "Asset") {
      TotalAssetAmount += item.AMOUNT;
    }
  });

  liabilityData.forEach((item) => {
    if (item.ASSETLIABILITY === "Liability") {
      TotalLiabilityAmount += item.AMOUNT;
    }
  });

  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }

  function handleView(value1, value2) {
    if (isValidDate(moment(date).format("DD/MM/YYYY"))) {
      navigate("/AnalyticsBasedOnNominalActivity", {
        state: { value1, value2, enddate: date, orgCode: CompanyCode },
      });
    } else {
      alert("Kindly Provide Valid Date");
    }
  }

  const BalanceSheet = () => {
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/BalanceSheet`,
        {
          date: moment(date).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setLoading2(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading2(false);
        console.log(error);
      });
  };

  const ExcelData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("BalanceSheet");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 20, 20, 15, 45, 20]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    const headerRow = worksheet.addRow([
      "ASSET/LIABILITY",
      "LEVEL NAME",
      "GROUP",
      "ACCOUNT CODE",
      "ACCOUNT NAME",
      "AMOUNT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "BalanceSheet.xlsx";
      link.click();
    });
  };

  const PdfData = () => {
    const doc = new jsPDF();

    doc.text("BalanceSheet", 10, 10);

    const columns = [
      "ASSET/LIABILITY",
      "LEVEL NAME",
      "GROUP",
      "ACCOUNT CODE",
      "ACCOUNT NAME",
      "AMOUNT",
    ];

    const pdfdata = assetData.map((assetData) => [
      assetData.ASSETLIABILITY,
      assetData.LEVELNAME,
      assetData.GROUP,
      assetData.ACCOUNTCODE,
      assetData.ACCOUNTNAME,
      assetData.AMOUNT,
    ]);
    doc.autoTable({
      head: [columns],
      body: pdfdata,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("BalanceSheet.pdf");
  };

  const PdfData1 = () => {
    const doc = new jsPDF();

    doc.text("BalanceSheet", 10, 10);

    const columns = [
      "ASSET/LIABILITY",
      "LEVEL NAME",
      "GROUP",
      "ACCOUNT CODE",
      "ACCOUNT NAME",
      "AMOUNT",
    ];

    const pdfdata = liabilityData.map((liabilityData) => [
      liabilityData.ASSETLIABILITY,
      liabilityData.LEVELNAME,
      liabilityData.GROUP,
      liabilityData.ACCOUNTCODE,
      liabilityData.ACCOUNTNAME,
      liabilityData.AMOUNT,
    ]);
    doc.autoTable({
      head: [columns],
      body: pdfdata,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("BalanceSheet.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/FinancialDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Balance Sheet View</h2>
      </div>
      <div className="balance_sheet_box_1">
        <div className="balance">
          <div className="bal_as_at">
            <p style={{ width: "55px" }}>
              <b> Date :</b>
            </p>
            <input
              className="as_at"
              value={date.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a date");
                } else {
                  setDate(e.target.value);
                }
              }}
            />
          </div>
          <div className="balance_Like">
            <p>
              <b>Search:</b>
            </p>
            <input
              className="input_Like"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
          </div>

          {/* <div className="balance_sheet_view_button">
            <button className="bal_view_button">View</button>
            <button className="bal_view_Exp_button">View Expanded</button>
          </div>
          <div className="balance_sheet_acc_button">
            <button className="bal_account">Account Groups</button>
            <button className="bal_acc_chart">Chart of Accounts</button>
          </div> */}
        </div>
      </div>
      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <div className="stock_icons">
            <div>
              <RiFileExcel2Line onClick={ExcelData} size={35} />
            </div>
            <div>
              <BsFileEarmarkPdf onClick={PdfData} size={35} />
            </div>
          </div>

          <aside className="stock_table">
            <div
              style={{
                marginTop: "20px",
                width: "95%",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td className="header_asset">ASSET/LIABILITY</td>
                    <td className="header_level">LEVEL NAME</td>
                    <td className="header_group">GROUP</td>
                    <td className="header_level">ACCOUNT CODE</td>
                    <td className="header_level">ACCOUNT NAME</td>
                    <td className="header_amount">AMOUNT</td>
                    <td style={{ fontWeight: "500", paddingLeft: "20px" }}>
                      ACTION
                    </td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {assetData
                    .filter((item) => item.AMOUNT !== 0)
                    .sort((a, b) => a.LEVELNAME.localeCompare(b.LEVELNAME))
                    .filter((item) => {
                      const searchKeywords = searchQuery
                        .toLowerCase()
                        .split(" ");

                      return searchKeywords.every((keyword) =>
                        Object.keys(item)
                          .filter((key) => key !== "AMOUNT")
                          .map((key) => String(item[key]).toLowerCase())
                          .join(" ")
                          .includes(keyword)
                      );
                    })
                    .map((item) => {
                      const cleanedAssetGroup = item.ASSETLIABILITY.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      const count = AssetGroupCounts[cleanedAssetGroup] || 0;
                      AssetGroupCounts[cleanedAssetGroup] = count + 1;

                      const cleanedLevel = item.LEVELNAME.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      const levelcount = AssetLevelCounts[cleanedLevel] || 0;
                      AssetLevelCounts[cleanedLevel] = count + 1;

                      return (
                        <tr className="tr_test2" key={item.GROUP}>
                          {count === 0 && (
                            <td className="asset">{item.ASSETLIABILITY}</td>
                          )}
                          {count > 0 && <td></td>}
                          {levelcount === 0 && (
                            <td className="asset">{item.LEVELNAME}</td>
                          )}
                          {levelcount > 0 && <td></td>}
                          <td>{item.GROUP}</td>
                          <td>{item.ACCOUNTCODE}</td>
                          <td>{item.ACCOUNTNAME}</td>
                          <td>
                            {item.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            <button
                              className="button"
                              type="button"
                              onClick={() => {
                                handleView(item.ACCOUNTCODE, item.ACCOUNTNAME);
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total Asset Amount:</td>
                    <td>
                      {TotalAssetAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </aside>
        </>
      )}

      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <div className="stock_icons">
            <div>
              <RiFileExcel2Line onClick={ExcelData} size={35} />
            </div>
            <div>
              <BsFileEarmarkPdf onClick={PdfData1} size={35} />
            </div>
          </div>

          <aside className="stock_table">
            <div
              style={{
                marginTop: "20px",
                width: "95%",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td className="header_asset">ASSET/LIABILITY</td>
                    <td className="header_level">LEVEL NAME</td>
                    <td className="header_group">GROUP</td>
                    <td className="header_level">ACCOUNT CODE</td>
                    <td className="header_level">ACCOUNT NAME</td>
                    <td className="header_amount">AMOUNT</td>
                    <td style={{ fontWeight: "500", paddingLeft: "20px" }}>
                      ACTION
                    </td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {liabilityData
                    .filter((item) => item.AMOUNT !== 0)
                    .sort((a, b) => a.LEVELNAME.localeCompare(b.LEVELNAME))
                    .filter((item) => {
                      const searchKeywords = searchQuery
                        .toLowerCase()
                        .split(" ");

                      return searchKeywords.every((keyword) =>
                        Object.keys(item)
                          .filter((key) => key !== "AMOUNT")
                          .map((key) => String(item[key]).toLowerCase())
                          .join(" ")
                          .includes(keyword)
                      );
                    })
                    .map((item) => {
                      const cleanedAssetGroup = item.ASSETLIABILITY.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      const count = AssetGroupCounts[cleanedAssetGroup] || 0;
                      AssetGroupCounts[cleanedAssetGroup] = count + 1;

                      const cleanedLevel = item.LEVELNAME.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      const levelcount = AssetLevelCounts[cleanedLevel] || 0;
                      AssetLevelCounts[cleanedLevel] = count + 1;

                      return (
                        <tr className="tr_test2" key={item.GROUP}>
                          {count === 0 && (
                            <td className="asset">{item.ASSETLIABILITY}</td>
                          )}
                          {count > 0 && <td></td>}
                          {levelcount === 0 && (
                            <td className="asset">{item.LEVELNAME}</td>
                          )}
                          {levelcount > 0 && <td></td>}
                          <td>{item.GROUP}</td>
                          <td>{item.ACCOUNTCODE}</td>
                          <td>{item.ACCOUNTNAME}</td>
                          <td>
                            {item.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            <button
                              className="button"
                              type="button"
                              onClick={() => {
                                handleView(item.ACCOUNTCODE, item.ACCOUNTNAME);
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total Liability Amount:</td>
                    <td>
                      {TotalLiabilityAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </aside>
        </>
      )}
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnBalanceSheet;
