import React from "react";
import { useNavigate } from "react-router-dom";

// import Footer from "./Components/Footer";
import AllPagesMasterNavbar from "../../../Components/MasterNavbar/AllPagesMasterNavbar";
import workshop from "../../../Images/workshop.PNG";
import fuel from "../../../Images/fuel.PNG";
import Breakdowns from "../../../Images/Breakdowns.PNG";
import last_anaysis from "../../../Images/last_anaysis.PNG";
import trip from "../../../Images/trip.PNG";
import costing from "../../../Images/costing.PNG";
import inventory from "../../../Images/inventory.PNG";
import analyticscnf from "../../../Images/analyticscnf.PNG";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../Components/features/userSlice";

function TransportDashboard1() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  return (
    <div>
      <AllPagesMasterNavbar />

      <div className="h2">
        <h2>Welcome to Transport Dashboards</h2>
      </div>

      <section
        className=" flex justify-around gap-5 lg:px-20 flex-wrap mt-5 "
        style={{ fontSize: "12px" }}
      >
        <div
          onClick={() => {
            navigate("/Workshop");
          }}
          className="align__img__and__heading"
        >
          <img src={workshop} height="150px" width="180px" alt="dashboard" />
          <h3>Workshop Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/FuelExpense");
          }}
          className="align__img__and__heading"
        >
          <img src={fuel} height="150px" width="200px" alt="dashboard" />
          <h3>Fuel Analytics</h3>
        </div>

        {/* <div
          onClick={() => {
            navigate("/BreakDowns");
          }}
          className="align__img__and__heading"
        >
          <img src={Breakdowns} height="150px" width="200px" alt="dashboard" />
          <h3>BreakDowns Analytics</h3>
        </div> */}
        <div
          onClick={() => {
            navigate("/InventoryAnalytics");
          }}
          className="align__img__and__heading"
        >
          <img src={inventory} height="150px" width="200px" alt="dashboard" />
          <h3>Inventory Analytics</h3>
        </div>
        {/* <div
          onClick={() => {
            navigate("/TripExpense");
          }}
          className="align__img__and__heading"
        >
          <img src={trip} height="150px" width="200px" alt="dashboard" />
          <h3>Trip Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/VehicleExpense");
          }}
          className="align__img__and__heading"
        >
          <img src={costing} height="150px" width="200px" alt="dashboard" />
          <h3>Cost Analytics</h3>
        </div> */}
        <div
          onClick={() => {
            navigate("/TransportAnalyticsMAIN");
          }}
          className="align__img__and__heading"
        >
          <img src={Breakdowns} height="150px" width="200px" alt="dashboard" />
          <h3>Transport Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/TransportExpenses");
          }}
          className="align__img__and__heading"
        >
          <img src={trip} height="150px" width="200px" alt="dashboard" />
          <h3>Transport Expenses</h3>
        </div>
        <div
          onClick={() => {
            navigate("/TransportIncome");
          }}
          className="align__img__and__heading"
        >
          <img src={costing} height="150px" width="200px" alt="dashboard" />
          <h3>Transport Income</h3>
        </div>
        <div
          onClick={() => {
            navigate("/FinancialDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={last_anaysis}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Financial Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/TrendAnanlysis");
          }}
          className="align__img__and__heading"
        >
          <img
            src={analyticscnf}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Trend Analytics</h3>
        </div>
      </section>
    </div>
  );
}

export default TransportDashboard1;
