import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../../Components/LoadingScreen";
import Footer from "../../Components/Footer";
import moment from "moment";
import "../../CSS/TransportUnapproved.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import { RxCross2 } from "react-icons/rx";

const DetailedDashboardView = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const [date, setDate] = useState(new Date().toISOString());
  const viewname = location.state?.value;
  const departmentname = location.state?.name;
  const rightName = location?.state?.right;
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const state = useSelector((state) => state?.user);
  const exclusiveRights = state?.exclusiveRights;
  const superUserAccounts = state?.superUserAccounts;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    startdate: "",
    enddate: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpdata, setPopUpData] = useState([]);
  const [hasRights, setHasRights] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    switch (viewname) {
      case "UNAPPROVED PAYMENTS":
        getInitialData("getAllPayments");
        break;
      case "UNAPPROVED PAYMENTS TYPE 2":
        getInitialData("getAllUnapprovedPaymentstype2");
        break;
      case "UNAPPROVED PURCHASE INVOICES":
        getInitialData("getPinvs");
        break;
      case "UNAPPROVED PURCHASE INVOICES 2":
        getInitialData("getPinvstype2");
        break;
      case "UNAPPAROVED JOURNALS":
        getInitialData("getJournalsnotApproved");
        break;
      case "UNAPPROVED PETTY CASH":
        getInitialData("getAllpettyCash");
        break;
      case "UNAPPROVED SALES INVOICES":
        getInitialData("getAllSalesInvoices");
        break;
      case "UNAPPROVED PURCHASE ORDERS":
        getInitialData("getAllPos");
        break;
      case "UNAPPROVED QUOTATIONS":
        getInitialData("getUnapprovedQuotations");
        break;
      case "UNAPPROVED CREDIT NOTES":
        getInitialData("getUnapprovedCredits");
        break;
      case "UNAPPROVED MATERIAL REQUISITIONS":
        getInitialData("getAllMaterialRequisition");
        break;
      case "UNAPPROVED PURCHASE ENQUIRIES":
        getInitialData("getAllUnapprovedPurchaseEnquiries/sum");
        break;
      case "UNAPPROVED MILEAGE VOUCHERS":
        getInitialData("getAlmeleageVouchers");
        break;
      case "UNAPPROVED FUEL VOUCHERS":
        getInitialData("getAlFuleVouchers");
        break;
      //addedbyme
      // case "UNAPPROVED PETTY CASH":
      //   getInitialData("getAllpettyCash");
      //   break;
      // case "UNAPPROVED PURCHASE INVOICES 2":
      //   getInitialData("getPinvstype2");
      //   break;

      default:
        setIsLoading(false);
    }
  }, [viewname, authToken]);

  function getInitialData(endpoint) {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/${endpoint}/sum`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while fetching initial data", err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const filteredExclusiveRights = exclusiveRights.filter(
      (right) => right.OM_USER_CODE === superUserAccounts
    );
    // console.log(rightName);

    // console.log(filteredExclusiveRights);
    const hasMatchingRights = filteredExclusiveRights?.some((right) => {
      const normalizedExclusiveRights =
        right?.OM_YES_NO_DESCRIPTION?.toLowerCase();
      // console.log(normalizedExclusiveRights);
      const normalizedViewname = rightName?.toLowerCase();
      // console.log(normalizedViewname);

      return normalizedExclusiveRights?.includes(normalizedViewname);
    });
    // console.log(hasMatchingRights);
    setHasRights(hasMatchingRights);
  }, [exclusiveRights, superUserAccounts, rightName]);

  // Filters
  // const filteredData = data.filter((item) => {
  //   const searchData = filters.search.toLowerCase().split(" ").filter(Boolean);
  //   const sDate = item.DATE;
  //   const eDate = item.DATE;

  //   if (searchData.length === 0 && !filters.startdate && !filters.enddate) {
  //     return true;
  //   }
  //   const textMatch = searchData.every((term) => {
  //     return Object.keys(item).some((key) => {
  //       const columnValue = String(item[key] || "").toLowerCase();
  //       return columnValue.includes(term);
  //     });
  //   });

  //   const dateMatch =
  //     (!filters.startdate || sDate >= filters.startdate) &&
  //     (!filters.enddate || eDate <= filters.enddate);

  //   return textMatch && dateMatch;
  // });

  const filteredData = data.filter((item) => {
    const searchData = filters.search.toLowerCase().split(" ").filter(Boolean);

    // Extract date fields from item
    const dateColumns = [
      "DATE",
      "DOCUMENTDATE",
      "PO DATE",
      "BILL DATE",
      "INVOICE DATE",
    ];
    const dateMatches = dateColumns.some((col) => {
      const itemDate = item[col] ? moment(item[col]).format("YYYY-MM-DD") : "";
      return (
        (!filters.startdate || itemDate >= filters.startdate) &&
        (!filters.enddate || itemDate <= filters.enddate)
      );
    });

    // Check if any date column matches the date filters
    const dateMatch = dateMatches;

    // Text match logic
    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return Object.keys(item).some((key) => {
          const columnValue = String(item[key] || "").toLowerCase();
          return columnValue.includes(term);
        });
      });

    return textMatch && dateMatch;
  });

  // ********************** To View in Details *******************************
  function getDetailedData(endpoint, column, value) {
    setIsLoading(true);
    const encodedColumn = encodeURIComponent(column);
    axios
      .get(`${BASEURL}/${endpoint}/all?${encodedColumn}=${value}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((res) => {
        setPopUpData(res?.data);
        setShowPopUp(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(`error while fetching Detaied Data Of ${endpoint} `, err);
      });
  }

  // ********* Selected Items From Table ********************************
  // const toggleRowSelection = (value, isChecked, compareKeys) => {
  //   const selectedIndex = selectedRows.findIndex((row) =>
  //     compareKeys.every((key) => row[key] === value[key])
  //   );
  //   console.log(selectedIndex);
  //   let updatedSelection = [...selectedRows];

  //   if (!isChecked) {
  //     if (selectedIndex !== -1) {
  //       updatedSelection.splice(selectedIndex, 1);
  //     }
  //   } else {
  //     if (selectedIndex === -1) {
  //       updatedSelection.push(value);
  //     } else {
  //       updatedSelection[selectedIndex] = value;
  //     }
  //   }
  //   console.log(updatedSelection);
  //   setSelectedRows(updatedSelection);
  // };

  // const toggleRowSelection = (value, isChecked, compareKeys) => {
  //   const isSelected = selectedRows.some((row) =>
  //     compareKeys.every((key) => row[key] === value[key])
  //   );

  //   console.log(isSelected);
  //   let updatedSelection = [...selectedRows];

  //   if (isChecked && !isSelected) {
  //     updatedSelection.push(value); // Add the value if not already selected
  //   } else if (!isChecked && isSelected) {
  //     updatedSelection = updatedSelection.filter(
  //       (row) => !compareKeys.every((key) => row[key] === value[key])
  //     ); // Remove the value if already selected
  //   }

  //   setSelectedRows(updatedSelection);
  // };

  const toggleRowSelection = (value, isChecked, compareKeys) => {
    let updatedSelection;

    if (isChecked) {
      // Add the value to the selected rows if it's not already selected
      updatedSelection = selectedRows.includes(value)
        ? selectedRows
        : [...selectedRows, value];
    } else {
      // Remove the value from the selected rows if it exists
      updatedSelection = selectedRows.filter((row) => row !== value);
    }

    setSelectedRows(updatedSelection);
  };

  // **************************** APPROVE OR CANCEL **************************
  const handleAction = async (action) => {
    // console.log(action);
    // console.log(viewname);
    if (action === "approve") {
      if (hasRights) {
        switch (viewname) {
          case "UNAPPROVED PAYMENTS":
            await ApprovePayments();
            break;
          case "UNAPPAROVED JOURNALS":
            await ApproveJournals();
            break;
          case "UNAPPROVED PETTY CASH":
            await ApprovePettyCashVoucher();
            break;
          case "UNAPPROVED SALES INVOICES":
            await approvesales();
            break;
          case "UNAPPROVED QUOTATIONS":
            await approveuotations();
            break;
          case "UNAPPROVED CREDIT NOTES":
            await approvecreditnotes();
            break;
          case "UNAPPROVED PURCHASE ENQUIRIES":
            await approvepurchaseenquiry();
          case "UNAPPROVED PAYMENTS TYPE 2":
            await ApprovePayments();
            break;
          case "UNAPPROVED PURCHASE INVOICES":
            await ApprovePurchaseOrders();
            break;
          case "UNAPPROVED PURCHASE INVOICES 2":
            await ApprovePurchaseOrders();
            break;
          case "UNAPPROVED PURCHASE ORDERS":
            await ApprovePOs();
            break;
          default:
            alert("Data Not Available to Approve");
        }
      } else {
        alert("You don't have rights to Approve");
      }
    } else {
      if (hasRights) {
        switch (viewname) {
          // case "UNAPPROVED PAYMENTS":
          //   await ApprovePayments();
          //   break;
          // case "UNAPPAROVED JOURNALS":
          //   await ApproveJournals();
          //   break;
          // case "UNAPPROVED PETTY CASH":
          //   await ApprovePettyCashVoucher();
          //   break;
          // case "UNAPPROVED SALES INVOICES":
          //   await approvesales();
          //   break;
          // case "UNAPPROVED QUOTATIONS":
          //   await approveuotations();
          //   break;
          case "UNAPPROVED CREDIT NOTES":
            await cancelcreditnotes();
            break;
          // case "UNAPPROVED PURCHASE ENQUIRIES":
          //   await approvepurchaseenquiry();
          // case "UNAPPROVED PAYMENTS TYPE 2":
          //   await ApprovePayments();
          //   break;
          // case "UNAPPROVED PURCHASE INVOICES":
          //   await ApprovePurchaseOrders();
          //   break;
          // case "UNAPPROVED PURCHASE INVOICES 2":
          //   await ApprovePurchaseOrders();
          //   break;
          default:
            alert("Data Not Available to cancel");
        }
      }
    }
  };

  // Approve Payments /ApprovePayments UNAPPROVED PAYMENTS
  const ApprovePayments = async () => {
    setIsLoading(true);

    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/ApprovePayments`,
          {
            PAYMENTNO: Number(value.DOCUMENTNO),
            APPROVEDCANCELLED: "APPROVE",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });
      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0]?.data.message) alert("Payment Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error("Error While Approving Payments :/ApprovePayments", error);
    }
  };

  // Approve Journals /ApproveJournals UNAPPAROVED JOURNALS
  const ApproveJournals = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/ApproveJournals`,
          {
            JOURNALNO: Number(value.DOCUMENTNO),
            APPROVEDCANCELLED: "APPROVE",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });
      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Journal Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error("Error While Approving Journals :/ApproveJournals", error);
    }
  };

  // Approve Petty Cash Voucher /ApprovePettyCashVoucher UNAPPROVED PETTY CASH JOURNALS
  const ApprovePettyCashVoucher = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/ApprovePettyCashVoucher`,
          {
            JOURNALNO: Number(value.DOCUMENTNO),
            APPROVEDCANCELLED: "",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });
      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Petty Cash Voucher Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Petty Cash Voucher :/ApprovePettyCashVoucher",
        error
      );
    }
  };

  // Approve Sales /approvesales UNAPPROVED SALES INVOICES
  const approvesales = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvesales`,
          {
            INVNO: Number(value["INVOICE NO"]),
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Sales Invoice Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error("Error While Approving Sales :/approvesales", error);
    }
  };

  // Approve Quotations /approveuotations UNAPPROVED QUOTATIONS
  const approveuotations = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approveuotations`,
          {
            DOCNO: Number(value["QUOTATION NO"]),
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Quotation Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Quotations :/approveuotations",
        error
      );
    }
  };

  // Approve Credit Notes /approvecreditnotes UNAPPROVED CREDIT NOTES
  const approvecreditnotes = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvecreditnotes`,
          {
            DOCNO: Number(value["CREDITNOTE NO"]),
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Credit Notes Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Credit Notes :/approvecreditnotes",
        error
      );
    }
  };
  //cancel credit note
  const cancelcreditnotes = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/cancelCreditNote`,
          {
            DOCNO: Number(value["CREDITNOTE NO"]),
            CANCELLEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Credit Notes Cancelled !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Credit Notes :/cancelreditnotes",
        error
      );
    }
  };

  // Approve Purchase Enquiry /approvepurchaseenquiry UNAPPROVED PURCHASE ENQUIRIES
  const approvepurchaseenquiry = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvepurchaseenquiry`,
          {
            ENQNO: Number(value["RFQ NO"]),
            SUPPLIERCODE: value.SUPPLIERCODE,
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Purchase Enquiry Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Purchase Enquiry :/approvepurchaseenquiry",
        error
      );
    }
  };
  //addbyme
  const ApprovePurchaseOrders = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvebills`,
          {
            PONO: Number(value["BILL NO"]),
            APPROVEDCANCELLED: "APPROVE",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Purchase Enquiry Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Purchase Enquiry :/approvepurchaseenquiry",
        error
      );
    }
  };

  // MT_APPROVE_PO;
  const ApprovePOs = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvePO`,
          {
            PONO: Number(value["PO NO"]),
            APPROVEDCANCELLED: "APPROVE",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Purchase Order Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Purchase Enquiry :/approvePO",
        error
      );
    }
  };

  // Approve Bills /approvebills
  const approvebills = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvebills`,
          {
            PONO: Number(value.DOCNO),
            APPROVEDCANCELLED: "APPROVE",
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
            APPROVALDATE: moment(date).format("YYYY/MM/DD"),
            CANCELLEDREASON: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Petty Cash Voucher Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error("Error While Approving Bills :/approvebills", error);
    }
  };

  // Approve Request To Issue /approvereuesttoIssue
  const approvereuesttoIssue = async () => {
    setIsLoading(true);
    try {
      const actionPromises = selectedRows.map((value) => {
        return axios.post(
          `${BASEURL}/approvereuesttoIssue`,
          {
            ISSUENOTENO: Number(value.DOCNO),
            ITEMCODE: value.ITEMCODE,
            ITEMNAME: value.ITEMNAME,
            ITEMCOST: Number(value.ITEMCOST),
            FINISHEDPRODUCTCODE: value.FINISHEDPRODUCTCODE,
            QTY: Number(value.QTY),
            APPROVEDBY: userEmail.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
      });

      const responses = await Promise.all(actionPromises);
      setIsLoading(false);
      if (responses[0].data.message) alert("Petty Cash Voucher Approved !");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error While Approving Request To Issue :/approvereuesttoIssue",
        error
      );
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Extract column headers from data keys
  const columnHeaders = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <p className="text-white">
          Logged in as : {userEmail.split("@")[0].toUpperCase()}
        </p>
        <div className="back_btn">
          <Link
            to="/APPROVALDASHBOARDS"
            style={{ textDecoration: "none", color: "white" }}
          >
            <button className="View">Back</button>
          </Link>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="interface__name">
          <h2>{departmentname}</h2>
        </div>
        <div>
          <p className="mr-6 mt-4">
            Total Number of{" "}
            <span className="text-black-600 ml-1 italic font-semibold">
              {viewname}
            </span>
            ={" "}
            <span className="text-red-600 font-bold">
              {filteredData?.length}
            </span>
          </p>
        </div>
      </div>

      {data.length > 0 ? (
        <>
          <div className="header__section">
            <div className="filter__section">
              {Object.keys(filters).map((key) => (
                <div key={key} className="flex gap-2">
                  <label className="font-semibold">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <input
                    type={key === "search" ? "text" : "date"}
                    value={filters[key] || ""}
                    onChange={(e) =>
                      setFilters({ ...filters, [key]: e.target.value })
                    }
                    className="border border-gray-300 rounded"
                  />
                </div>
              ))}
            </div>
            <div className="buttons__section">
              <button
                className="button__style"
                onClick={() => handleAction("approve")}
              >
                Approve
              </button>
              <button
                className="button__style"
                onClick={() => handleAction("cancel")}
              >
                Cancel
              </button>
            </div>
          </div>

          {showPopUp && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[100] bg-white border border-black lg:h-[500px] h-[80%] lg:w-[1300px] w-[95%] p-1 overflow-y-auto">
              <div className="header__section p-4">
                {popUpdata.length > 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
                    {Object.keys(popUpdata[0]).map((key) => {
                      const isCommon = popUpdata.every(
                        (item) => item[key] === popUpdata[0][key]
                      );
                      if (isCommon) {
                        return (
                          <div key={key} className="flex flex-col mb-4">
                            <label className="font-semibold text-gray-700">
                              {key}
                            </label>
                            <span className="text-gray-900">
                              {typeof popUpdata[0][key] === "string" &&
                              popUpdata[0][key].includes("T00:00:00.000Z")
                                ? moment(popUpdata[0][key]).format("DD/MM/YYYY")
                                : popUpdata[0][key] || "N/A"}
                            </span>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
              {/* <div className="buttons__section flex justify-end p-4">
                <button
                  className="button__style mx-2 bg-blue-500 text-white px-4 py-2 rounded"
                  onClick={() => handleAction("approve")}
                >
                  Approve
                </button>
                <button
                  className="button__style mx-2 bg-red-500 text-white px-4 py-2 rounded"
                  onClick={() => handleAction("cancel")}
                >
                  Cancel
                </button>
              </div> */}
              <RxCross2
                onClick={() => setShowPopUp(false)}
                className="absolute top-0 right-2 font-bold"
                size={25}
                color="red"
              />
              <div className="table-container mx-auto p-4 overflow-x-auto">
                <table className="w-full text-left table-auto border-collapse text-[12px]">
                  <thead className="bg-[#002d62] text-white">
                    <tr>
                      {popUpdata && popUpdata.length > 0 ? (
                        Object.keys(popUpdata[0]).map((header) => {
                          // Bypass the 'isCommon' check for 'UNAPPROVEDAMOUNT'
                          if (header === "UNAPPROVEDAMOUNT") {
                            return (
                              <th className="py-2 px-4" key={header}>
                                {header.toUpperCase()}
                              </th>
                            );
                          }

                          // Check for common values for all other headers
                          const isCommon = popUpdata.every(
                            (item) => item[header] === popUpdata[0][header]
                          );
                          if (!isCommon) {
                            return (
                              <th className="py-2 px-4" key={header}>
                                {header.toUpperCase()}
                              </th>
                            );
                          }
                          return null;
                        })
                      ) : (
                        <th className="py-2 px-4">No Data Available</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-300">
                    {popUpdata && popUpdata.length > 0 ? (
                      popUpdata.map((item, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          {Object.keys(item).map((key) => {
                            // Always include UNAPPROVEDAMOUNT without checking for common values
                            if (key === "UNAPPROVEDAMOUNT") {
                              return (
                                <td className="py-2 px-4" key={key}>
                                  {typeof item[key] === "number"
                                    ? `${item[key].toLocaleString()}`
                                    : item[key] || "N/A"}
                                </td>
                              );
                            }

                            // Check for common values for other keys
                            const isCommon = popUpdata.every(
                              (row) => row[key] === item[key]
                            );

                            if (!isCommon) {
                              return (
                                <td className="py-2 px-4" key={key}>
                                  {typeof item[key] === "string" &&
                                  item[key].includes("T00:00:00.000Z")
                                    ? moment(item[key]).format("DD/MM/YYYY")
                                    : item[key] || "N/A"}
                                </td>
                              );
                            }
                            return null;
                          })}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%" className="py-2 px-4 text-center">
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* Main Table */}

          <div className="table-container p-2 overflow-x-hidden">
            <table className="w-full text-left table-auto border-collapse text-[10px]">
              <thead className="bg-[#002d62] text-white">
                <tr>
                  <th className="py-2 px-4"></th>
                  {columnHeaders?.map((header) => (
                    <th
                      className={`py-2 px-4 ${
                        [
                          "AMOUNT",
                          "UNAPPROVEDAMOUNT",
                          "VAT AMT",
                          "TOTAL AMT",
                          "AMT EXC VAT",
                          "TOTAL AMOUNT",
                          "AMT INC VAT",
                          "AMOUNT INC VAT",
                          "ITEM COST",
                          "DOCUMENTNO",
                          "DAYS",
                          "BILL NO",
                          "INVOICE NO",
                          "PO NO",
                          "QUOTATION NO",
                          "CREDITNOTE NO",
                          "DOC NO",
                          "QTY",
                          "RFQ NO",
                          "JOB CARD NO",
                        ].includes(header)
                          ? "text-right"
                          : ""
                      }`}
                      key={header}
                    >
                      {header.toUpperCase()}
                    </th>
                  ))}
                  <th className="py-3 px-4">ACTION</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {filteredData?.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        className="w-4"
                        checked={selectedRows.includes(item)}
                        onChange={(e) => {
                          if (viewname === "UNAPPROVED CREDIT NOTES") {
                            toggleRowSelection(item, e.target.checked, [
                              "CREDITNOTE NO",
                              "DATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED PAYMENTS" ||
                            viewname === "UNAPPROVED PAYMENTS TYPE 2" ||
                            viewname === "UNAPPAROVED JOURNALS" ||
                            viewname === "UNAPPROVED PETTY CASH"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "DOCUMENTNO",
                              "DOCUMENTDATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED PURCHASE ORDERS"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "PO NO",
                              "PO DATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED PURCHASE INVOICES" ||
                            viewname === "UNAPPROVED PURCHASE INVOICES 2"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "BILL NO",
                              "BILL DATE",
                            ]);
                          } else if (viewname === "UNAPPROVED SALES INVOICES") {
                            toggleRowSelection(item, e.target.checked, [
                              "INVOICE NO",
                              "INVOICE DATE",
                            ]);
                          } else if (viewname === "UNAPPROVED QUOTATIONS") {
                            toggleRowSelection(item, e.target.checked, [
                              "QUOTATION NO",
                              "DATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED MATERIAL REQUISITIONS"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "DOC NO",
                              "DATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED PURCHASE ENQUIRIES"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "RFQ NO",
                              "DATE",
                            ]);
                          } else if (
                            viewname === "UNAPPROVED MILEAGE VOUCHERS" ||
                            viewname === "UNAPPROVED FUEL VOUCHERS"
                          ) {
                            toggleRowSelection(item, e.target.checked, [
                              "VOUCHER NO",
                              "DATE",
                            ]);
                          }
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </td>
                    {columnHeaders?.map((header) => (
                      <td
                        className={`py-2 px-4 ${
                          [
                            "AMOUNT",
                            "UNAPPROVEDAMOUNT",
                            "VAT AMT",
                            "TOTAL AMT",
                            "AMT EXC VAT",
                            "TOTAL AMOUNT",
                            "AMT INC VAT",
                            "AMOUNT INC VAT",
                            "ITEM COST",
                            "DOCUMENTNO",
                            "DAYS",
                            "BILL NO",
                            "INVOICE NO",
                            "PO NO",
                            "QUOTATION NO",
                            "CREDITNOTE NO",
                            "DOC NO",
                            "QTY",
                            "RFQ NO",
                            "JOB CARD NO",
                          ].includes(header)
                            ? "text-right"
                            : ""
                        }`}
                        key={header}
                      >
                        {header === "DATE" ||
                        header === "DOCUMENTDATE" ||
                        header === "PO DATE" ||
                        header === "BILL DATE" ||
                        header === "INVOICE DATE"
                          ? moment(item[header]).format("DD/MM/YYYY")
                          : header === "UNAPPROVEDAMOUNT" ||
                            header === "VAT AMT" ||
                            header === "TOTAL AMT" ||
                            header === "AMT EXC VAT" ||
                            header === "TOTAL AMOUNT" ||
                            header === "AMT INC VAT" ||
                            header === "AMOUNT INC VAT" ||
                            header === "AMOUNT" ||
                            header === "ITEM COST"
                          ? item[header]?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : item[header] || "N/A"}
                      </td>
                    ))}
                    <td className="w-1/12 py-3 px-4">
                      <button
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                        onClick={() => {
                          switch (viewname) {
                            case "UNAPPROVED PAYMENTS":
                              getDetailedData(
                                "getAllPayments",
                                "DOCUMENTNO",
                                item.DOCUMENTNO
                              );
                              break;
                            case "UNAPPROVED PAYMENTS TYPE 2":
                              getDetailedData(
                                "getAllUnapprovedPaymentstype2",
                                "DOCUMENTNO",
                                item.DOCUMENTNO
                              );
                              break;
                            case "UNAPPROVED PURCHASE INVOICES":
                              getDetailedData(
                                "getPinvs",
                                "BILL NO",
                                item["BILL NO"]
                              );
                              break;
                            case "UNAPPROVED PURCHASE INVOICES 2":
                              getDetailedData(
                                "getPinvstype2",
                                "BILL NO",
                                item["BILL NO"]
                              );
                              break;
                            case "UNAPPAROVED JOURNALS":
                              getDetailedData(
                                "getJournalsnotApproved",
                                "DOCUMENTNO",
                                item.DOCUMENTNO
                              );
                              break;
                            case "UNAPPROVED PETTY CASH":
                              getDetailedData(
                                "getAllpettyCash",
                                "DOCUMENTNO",
                                item.DOCUMENTNO
                              );
                              break;
                            case "UNAPPROVED SALES INVOICES":
                              getDetailedData(
                                "getAllSalesInvoices",
                                "INVOICE NO",
                                item["INVOICE NO"]
                              );
                              break;
                            case "UNAPPROVED PURCHASE ORDERS":
                              getDetailedData(
                                "getAllPos",
                                "PO NO",
                                item["PO NO"]
                              );
                              break;
                            case "UNAPPROVED QUOTATIONS":
                              getDetailedData(
                                "getUnapprovedQuotations",
                                "QUOTATION NO",
                                item["QUOTATION NO"]
                              );
                              break;
                            case "UNAPPROVED CREDIT NOTES":
                              getDetailedData(
                                "getUnapprovedCredits",
                                "CREDITNOTE NO",
                                item["CREDITNOTE NO"]
                              );
                              break;
                            case "UNAPPROVED MATERIAL REQUISITIONS":
                              getDetailedData(
                                "getAllMaterialRequisition",
                                "DOC NO",
                                item["DOC NO"]
                              );
                              break;
                            case "UNAPPROVED PURCHASE ENQUIRIES":
                              getDetailedData(
                                "getAllUnapprovedPurchaseEnquiries",
                                "RFQ NO",
                                item["RFQ NO"]
                              );
                              break;
                            case "UNAPPROVED MILEAGE VOUCHERS":
                              getDetailedData(
                                "getAlmeleageVouchers",
                                "VOUCHER NO",
                                item["VOUCHER NO"]
                              );
                              break;
                            case "UNAPPROVED FUEL VOUCHERS":
                              getDetailedData(
                                "getAlFuleVouchers",
                                "VOUCHER NO",
                                item["VOUCHER NO"]
                              );
                              break;

                            default:
                              alert("These View Is not Availble");
                          }
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <h1 className="flex justify-center text-[30px] font-bold">
            There is no data available for {departmentname}
          </h1>
        </>
      )}
      <Footer />
    </div>
  );
};

export default DetailedDashboardView;
