import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Loading2 from "../../../../Components/Loading2";
import { Link } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import Select from "react-select";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const PurchasesSupplierwisePeriodwise = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading2, setLoading2] = useState(false);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  // Options for the select dropdown
  const industryOptions = [
    { value: "", label: "All" }, // Include "All" option to reset filter
    { value: "TRADING", label: "TRADING" },
    { value: "TRANSPORT", label: "TRANSPORT" },
  ];
  const [todate, setTodate] = useState(new Date().toISOString());
  const [analyticsData, setanalyticsData] = useState([]);
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [fromdate, todate, selvehicleNo]);
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  async function fetchAnalysis() {
    setLoading2(true);
    const formattedFromDate = fromdate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const formattedToDate = todate.split("T")[0].split("-").reverse().join("/");
    console.log(formattedFromDate);
    try {
      if (isValidDate(formattedFromDate) && isValidDate(formattedToDate)) {
        const response = await axios.post(
          `${BASEURL}/purchasesSupplierwisePeriodwise `,
          {
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response);
        setLoading2(false);
        setanalyticsData(response.data);
      } else {
        //   alert("Select start and end date");
        setLoading2(false);
      }
    } catch {}
  }
  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [todate, fromdate]);
  const filteredData = analyticsData.filter((item) => {
    const industryMatch =
      !selectedIndustry ||
      selectedIndustry.value === "" ||
      item?.INDUSTRY === selectedIndustry.value;

    return industryMatch;
  });
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
        </div>
        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <section className="flex mt-5 flex-wrap gap-3 justify-center lg:justify-between flex-col-reverse lg:flex-row">
        {filteredData.length > 0 && (
          <div className="overflow-y-auto max-h-[400px] px-4  lg:w-[25%] overflow-x-hidden ">
            <table className="custom-table text-sm overflow__y__ relative w-full ">
              <thead
                className="header-cell sticky top-[0px] left-[10px] text-[10px]"
                style={{ zIndex: 20 }}
              >
                <tr>
                  <td className="text-right"> PERIOD</td>
                  <td className="">SUPPLIER NAME </td>
                  <td className="text-right">PURCHASES </td>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="text-[10px]"
                    >
                      {" "}
                      <td className=" ">
                        {item.PERIOD
                          ? moment(item?.PERIOD).format(" MMM YYYY")
                          : "0"}
                      </td>
                      <td className=" text">
                        {item["SUPPLIERNAME"] ? item["SUPPLIERNAME"] : "0"}
                      </td>{" "}
                      <td className=" text-right">
                        {item["PURCHASES"]
                          ? Number(item["PURCHASES"]).toLocaleString()
                          : "0"}
                      </td>{" "}
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr className="font-bold text-[10px]">
                  <td className="text-left">TOTAL</td>
                  <td></td>
                  <td className="text-right">
                    {filteredData
                      .reduce((sum, item) => sum + (item["PURCHASES"] || 0), 0)
                      .toLocaleString()}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        <div
          style={{ margin: "0 auto" }}
          onClick={() => setActiveDates(false)}
          className="lg:w-[70%] w-full"
        >
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              {filteredData.length > 0 ? (
                <div>
                  <div className="chart__active__buttons">
                    <button
                      className={
                        chartType === "bar"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("bar")}
                    >
                      <IoBarChartOutline />
                    </button>
                    <button
                      className={
                        chartType === "pie"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("pie")}
                    >
                      <FaChartPie />
                    </button>

                    <button
                      className={
                        chartType === "scatter"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("scatter")}
                    >
                      <BiScatterChart />
                    </button>
                    <button
                      className={
                        chartType === "area"
                          ? "active__chart "
                          : "not__active__chart  "
                      }
                      onClick={() => handleButtonClick("area")}
                    >
                      <FaChartArea />
                    </button>
                  </div>
                  {chartType === "bar" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "bar-chart",
                          toolbar: {
                            show: true,
                          },
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "55%",
                            endingShape: "rounded",
                          },
                        },
                        xaxis: {
                          type: "category",
                          categories: filteredData.map(
                            (item) => item.SUPPLIERNAME
                          ),
                          title: {
                            text: "Period",
                          },
                        },
                        yaxis: {
                          title: {
                            text: "SUPPLIER NAME",
                          },
                        },
                        zaxis: {
                          title: {
                            text: "Total Cost",
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        tooltip: {
                          y: {
                            formatter: (value) =>
                              Number(value).toLocaleString(),
                          },
                        },
                      }}
                      series={[
                        {
                          name: "Total Cost",
                          data: filteredData.map((item) => ({
                            x: item?.SUPPLIERNAME,
                            y: item?.PURCHASES,
                            z: moment(item?.PERIOD).format("MMM YYYY"),
                          })),
                        },
                      ]}
                      type="bar"
                      height={300}
                    />
                  )}
                  {chartType === "area" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "area-chart", // Change the ID for the area chart
                        },
                        xaxis: {
                          categories: filteredData.map(
                            (item) => item.SUPPLIERNAME
                          ),
                          title: {
                            text: "Month", // X-axis label
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "Purchases", // Y-axis label
                            },
                          },
                        ],
                        dataLabels: {
                          enabled: false, // Disable the data labels for area chart
                        },
                      }}
                      series={[
                        {
                          name: "Purchases",
                          data: filteredData.map((item) => item?.PURCHASES),
                        },
                      ]}
                      type="area" // Change chart type to "area"
                      height={300}
                    />
                  )}

                  {chartType === "pie" && (
                    <ReactApexChart
                      options={{
                        labels: filteredData.map((item) => item.SUPPLIERNAME),
                        title: {
                          text: "Pie Chart", // Title of the pie chart
                        },
                        dataLabels: {
                          enabled: true, // Enable data labels for pie chart
                        },
                        tooltip: {
                          enabled: true,
                          y: [
                            {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "Purchases";
                                return name + ": " + val;
                              },
                            },
                          ],
                        },
                      }}
                      series={filteredData.map((item) => item?.PURCHASES)}
                      type="pie" // Set chart type to "pie"
                      height={300}
                    />
                  )}

                  {chartType === "scatter" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "scatter-chart",
                          toolbar: {
                            show: true,
                          },
                        },
                        plotOptions: {
                          scatter: {
                            markers: {
                              size: 6,
                            },
                          },
                        },
                        xaxis: {
                          type: "category",
                          categories: filteredData.map(
                            (item) => item.SUPPLIERNAME
                          ),
                          title: {
                            text: "Period",
                          },
                        },
                        yaxis: {
                          title: {
                            text: "Purchases",
                          },
                        },
                        zaxis: {
                          title: {
                            text: "Supplier Name",
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                      series={[
                        {
                          name: "Total Cost",
                          data: filteredData.map((item) => ({
                            x: item?.SUPPLIERNAME,
                            y: item?.PURCHASES,
                            z: moment(item?.PERIOD).format("MM YYYY"),
                          })),
                        },
                      ]}
                      type="scatter"
                      height={300}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    marginTop: "50px",
                    textDecoration: "underline",
                  }}
                >
                  {selvehicleNo && <p> No Data Available</p>}
                  {!selvehicleNo && <p> Select Purchases</p>}
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default PurchasesSupplierwisePeriodwise;
