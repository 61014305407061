import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import "../../../../CSS/AnalyticsBasedOnTrialBalance.css";
import axios from "axios";
import moment from "moment";
import Footer from "../../../../Components/Footer";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import { useSelector } from "react-redux";

const AnalyticsBasedOnTrialBalance = () => {
  const [date, setDate] = useState(new Date().toISOString());
  const [data, setData] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  // let lastAccountGroup = null;
  let accountGroupCounts = {};
  const CompanyCode = useSelector(
    (state) => state?.user?.companyCode?.companyCode
  );

  useEffect(() => {
    TrialBalance();
    setIsloading(false);
  }, [date]);

  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }

  function handleView(value1, value2) {
    if (isValidDate(moment(date).format("DD/MM/YYYY"))) {
      navigate("/AnalyticsBasedOnNominalActivity", {
        state: { value1, value2, enddate: date, orgCode: CompanyCode },
      });
    } else {
      alert("Kindly Provide Valid Date");
    }
  }

  const TrialBalance = () => {
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/TrialBalance`,
        {
          date: moment(date).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setLoading2(false);
      })
      .catch((error) => {
        setLoading2(false);
        console.log(error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/FinancialDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Trial Balance
        </h2>
      </div>
      <div className="Trial_balance">
        {/* <p> Year</p>
        <Select className="select_sort_by" options={[]} />
        <p> Start Date</p>
        <input type="Date"></input>
        <p>End Date</p>
        <input type="Date"></input> */}
        <div className="trial_as_at">
          <p style={{ width: "55px" }}>
            <b>Date:</b>
          </p>
          <input
            className="as_at"
            value={date.split("T")[0]}
            type="date"
            onChange={(e) => {
              if (e.target.value === "") {
                alert("Please select a date");
              } else {
                setDate(e.target.value);
              }
            }}
          />
        </div>
        <div className="balance_Like">
          <p>
            <b>Search:</b>
          </p>
          <input
            className="input_Like"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          ></input>
        </div>
        {/* <div className="trial_balance">
          <p>
            <b>Currency :</b>
          </p>
          <Select className="" options={[]} />
        </div> */}
      </div>
      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <aside className="stock_table">
            <div
              style={{
                width: "95%",
                marginTop: "20px",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td>ACCOUNT GROUP</td>
                    <td>ACCOUNT CODE</td>
                    <td>ACCOUNT NAME</td>
                    <td>AMOUNT</td>
                    <td style={{ fontWeight: "500", paddingLeft: "20px" }}>
                      ACTION
                    </td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {data
                    .filter((item) => item.AMOUNT !== 0)
                    .map((item) => {
                      // Remove extra whitespace from ACCOUNTGROUP and convert to uppercase
                      const cleanedAccountGroup = item.ACCOUNTGROUP.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      // Get the count of this ACCOUNTGROUP so far
                      const count =
                        accountGroupCounts[cleanedAccountGroup] || 0;

                      // Increment the count for this ACCOUNTGROUP
                      accountGroupCounts[cleanedAccountGroup] = count + 1;

                      return (
                        <tr className={"tr_test1"} key={item.ACCOUNTCODE}>
                          {count === 0 && <td>{item.ACCOUNTGROUP}</td>}
                          {count > 0 && <td></td>}
                          <td>{item.ACCOUNTCODE}</td>
                          <td>{item.ACCOUNTNAME}</td>
                          <td>
                            {item.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            <button
                              className="button"
                              type="button"
                              onClick={() => {
                                handleView(item.ACCOUNTCODE, item.ACCOUNTNAME);
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </aside>
        </>
      )}
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnTrialBalance;
