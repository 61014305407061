import React, { useEffect, useState } from "react";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import LoadingScreen from "../../../../Components/LoadingScreen";
import TrendRevenueDivisionWise from "./TrendRevenueDivisionWise";
import TrendCompanyLevel from "./TrendCompanyLevel";
import ExpensesTrendAnalysisDivisionwise from "./ExpensesTrendAnalysisDivisionwise";
import RevenueTrendAnalysisCompanyLevel from "./RevenueTrendAnalysisCompanyLevel";
import GrossNetProfitsDivisionwise from "./GrossNetProfitsDivisionwise";
import GrossNetProfitsCompanyLevel from "./GrossNetProfitsCompanyLevel";
import "../../../../CSS/ListofVehicles.css";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";

const TransportExpenses = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "TrendRevenueDivisionWise",
    label: "Trend Revenue Division Wise",
  });
  const options = [
    {
      value: "TrendRevenueDivisionWise",
      label: "Trend Revenue Division Wise",
    },

    {
      value: "TrendCompanyLevel",
      label: "Trend Company Level",
    },

    {
      value: "ExpensesTrendAnalysisDivisionwise",
      label: "Expenses Trend Analysis Divisionwise",
    },

    {
      value: "RevenueTrendAnalysisCompanyLevel",
      label: "Expenses Trend Analysis Company Level",
    },
    {
      value: "GrossNetProfitsDivisionwise",
      label: "Gross Net Profits Division wise",
    },

    {
      value: "GrossNetProfitsCompanyLevel",
      label: "Gross Net Profits Company Level",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Trend Analysis</h2>

        <div
          className="lg:w-[400px] text-sm w-[100%] mx-2 "
          style={{ zIndex: 999 }}
        >
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "TrendRevenueDivisionWise" && (
            <TrendRevenueDivisionWise />
          )}
        {selectedComponent &&
          selectedComponent.value === "TrendCompanyLevel" && (
            <TrendCompanyLevel />
          )}

        {selectedComponent &&
          selectedComponent.value === "ExpensesTrendAnalysisDivisionwise" && (
            <ExpensesTrendAnalysisDivisionwise />
          )}

        {selectedComponent &&
          selectedComponent.value === "RevenueTrendAnalysisCompanyLevel" && (
            <RevenueTrendAnalysisCompanyLevel />
          )}

        {selectedComponent &&
          selectedComponent.value === "GrossNetProfitsDivisionwise" && (
            <GrossNetProfitsDivisionwise />
          )}
        {selectedComponent &&
          selectedComponent.value === "GrossNetProfitsCompanyLevel" && (
            <GrossNetProfitsCompanyLevel />
          )}
      </div>
    </div>
  );
};

export default TransportExpenses;
